import React, { useCallback, useEffect, useState } from "react";
import BlockContent from "@sanity/block-content-to-react";
import { getAbout } from "./client";

const About = () => {
  const [about, setResources] = useState<any>();
  const fetchAbout = useCallback(async () => {
    const about = await getAbout();
    setResources(about);
    return about;
  }, []);

  useEffect(() => {
    fetchAbout();
  }, [fetchAbout]);

  const serializers = {
    types: {
      block: (props: any) => {
        return <p className="leading-relaxed mt-6">{props.children}</p>;
      },
    },
  };

  return (
    <div className="max-w-4xl h-full lg:h-4/6 mx-auto my-0 lg-my-32 lg:my-0 px-4 lg:px-0 text-white pb-10">
      <h1 className="text-2xl tracking-wider font-extrabold">
        Meet Jennifer Bonial
      </h1>
      {about && (
        <>
          <BlockContent
            className="leading-relaxed"
            blocks={about.meet}
            serializers={serializers}
          />

          <h2 className="text-xl mt-6 font-bold leading-relaxed">From Jenny</h2>

          <BlockContent
            className="leading-relaxed"
            blocks={about.from}
            serializers={serializers}
          />
        </>
      )}
    </div>
  );
};

export default About;
