import { createClient } from "@sanity/client";

export const client = createClient({
  projectId: "ms6voz6x",
  dataset: "production",
  apiVersion: "2024-08-19",
  useCdn: true,
});

export const getResources = async () => {
  const resources = await client.fetch('*[_type == "resource"]');
  return resources;
};

export const getAbout = async () => {
  const about = await client.fetch('*[_type == "about"][0]');
  return about;
};

export const getServices = async () => {
  const services = await client.fetch('*[_type == "service"]');
  return services;
};
