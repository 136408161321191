import React, { useEffect, useState } from "react";

const ContactForm = () => {
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (window.location.search.includes("success=true")) {
      setSuccess(true);
    }
  }, []);

  return (
    <div className="max-w-4xl h-full lg:h-4/6 mx-auto my-0 lg-my-32 lg:my-0 px-4 lg:px-0 text-white pb-10">
      <h1 className="text-2xl">Contact</h1>

      <form name="contact" method="POST"  action="/contact/?success=true" className="mt-6">
        <input type="hidden" name="form-name" value="contact" />
        <p>
          <label htmlFor="name" className="font-bold">
            Name
          </label>{" "}
          <br />
          <input
            type="text"
            id="name"
            name="name"
            required
            className="mt-1 py-2 px-4 rounded-md text-black w-full md:w-4/12"
          />
        </p>
        <p className="mt-4">
          <label htmlFor="email" className="font-bold">
            Email
          </label>{" "}
          <br />
          <input
            type="email"
            id="email"
            name="email"
            required
            className="mt-1 py-2 px-4 rounded-md text-black  w-full md:w-4/12"
          />
        </p>
        <p className="mt-4">
          <label htmlFor="childs-grade" className="font-bold">
            Child's grade
          </label>{" "}
          <br />
          <input
            type="text"
            id="childs-grade"
            name="childs-grade"
            required
            className="mt-1 py-2 px-4 rounded-md text-black  w-full md:w-4/12"
          />
        </p>
        <p className="mt-4">
          <label htmlFor="childs-district" className="font-bold">
            Child's school district
          </label>{" "}
          <br />
          <input
            type="text"
            id="childs-district"
            name="childs-district"
            required
            className="mt-1 py-2 px-4 rounded-md text-black  w-full md:w-4/12"
          />
        </p>
        <p className="mt-4">
          <p className="font-bold">I would like help with</p>
          <p className="mt-1">
            <input
              type="checkbox"
              id="initial-consultation"
              name="service-type"
              value="initial-consultation"
              className="mr-2"
            />
            <label htmlFor="initial-consultation" className="pb-2">
              Initial consultation
            </label>
          </p>
          <p>
            <input
              type="checkbox"
              id="comprehensive-advocacy-services"
              name="service-type"
              value="comprehensive-advocacy-services"
              className="mr-2"
            />
            <label htmlFor="comprehensive-advocacy-services">
              Comprehensive advocacy services
            </label>
          </p>
          <p>
            <input
              type="checkbox"
              id="iep-review"
              name="service-type"
              value="iep-review"
              className="mr-2"
            />
            <label htmlFor="iep-review">IEP review</label>
          </p>
          <p>
            <input
              type="checkbox"
              id="504-review"
              name="service-type"
              value="504-review"
              className="mr-2"
            />
            <label htmlFor="504-review">504 review</label>
          </p>
          <p>
            <input
              type="checkbox"
              id="records-review"
              name="service-type"
              value="records-review"
              className="mr-2"
            />
            <label htmlFor="records-review">Records review</label>
          </p>
        </p>

        <p className="mt-4">
          <label htmlFor="message" className="font-bold">
            Additional info
          </label>{" "}
          <br />
          <textarea
            id="message"
            name="message"
            className="mt-1 py-2 px-4 rounded-md text-black w-full md:w-7/12"
            rows={5}
          ></textarea>
        </p>
        {success && <div className="mt-6 p-4 rounded-md bg-green-200 text-gray-700 font-bold">Thank you for your request. I'll be in touch shortly.</div>}
        <p className="mt-4">
          <input
            type="submit"
            value="Request service"
            disabled={success}
            className="bg-cyan-700 hover:bg-cyan-900 disabled:bg-cyan-500 text-white disabled:text-opacity-75 font-bold py-2 px-4 rounded-full w-full md:w-3/12"
          />
        </p>
      </form>
    </div>
  );
};

export default ContactForm;
