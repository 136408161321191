import React, { useCallback, useEffect, useState } from "react";
import BlockContent from "@sanity/block-content-to-react";
import { getServices } from "./client";

const Services = () => {
  const [services, setServices] = useState<any>();
  const fetchServices = useCallback(async () => {
    const services = await getServices();
    // sort services by _createdAt datetime (e.g. 2024-08-19T20:33:20)
    services.sort(
      (a: any, b: any) =>
        new Date(a._createdAt).getTime() - new Date(b._createdAt).getTime()
    );
    setServices(services);
  }, []);

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  const serializers = {
    types: {
      block: (props: any) => {
        return <p className="leading-relaxed mb-4 mt-2">{props.children}</p>;
      },
    },
  };

  return (
    <div className="max-w-4xl h-full lg:h-4/6 mx-auto my-0 lg-my-32 lg:my-0 px-4 lg:px-0 text-white pb-10">
      <h1 className="text-2xl tracking-wider font-extrabold">Services</h1>
      {services &&
        services.map((service: any) => (
          <div key={service._id}>
            <h2 className="text-xl font-bold tracking-wider mt-6">
              {service.title}
            </h2>
            <BlockContent
              blocks={service.description}
              serializers={serializers}
            />
            {service.formButtonUrl && (
              <a
                href={service.formButtonUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="mt-6 bg-cyan-700 hover:bg-cyan-900 text-white font-bold py-2 px-4 rounded-full"
              >
                {service.buttonText}
              </a>
            )}
          </div>
        ))}

      <p className="mt-6 text-sm italic">
        Jennifer Bonial is a non-attorney advocate. Boulder Education Advocacy
        does not provide legal services or legal advice of any kind.
      </p>
    </div>
  );
};

export default Services;
