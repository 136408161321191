import React, { useCallback, useEffect, useState } from "react";
import BlockContent from "@sanity/block-content-to-react";
import { getResources } from "./client";

const Resources = () => {
  const [resources, setResources] = useState<any>();
  const fetchResources = useCallback(async () => {
    const resources = await getResources();
    setResources(resources.reverse());
    return resources;
  }, []);

  useEffect(() => {
    fetchResources();
  }, [fetchResources]);

  const serializers = {
    types: {
      block: (props: any) => {
        return <p className="leading-relaxed">{props.children}</p>;
      },
    },
  };

  return (
    <div className="max-w-4xl h-full lg:h-4/6 mx-auto my-0 lg-my-32 lg:my-0 px-4 lg:px-0 text-white pb-10">
      <h1 className="text-2xl tracking-wider font-extrabold">Resources</h1>
      <ul className="mt-6">
        {resources &&
          resources.map((resource: any) => (
            <li className="mt-6" key={resource._id}>
              <a
                href={resource.url}
                target="_blank"
                rel="noopener noreferrer"
                className="underline text-lg"
              >
                {resource.title}
              </a>
              {resource.description && (
                <BlockContent
                  className="leading-relaxed"
                  blocks={resource.description}
                  serializers={serializers}
                />
              )}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Resources;
