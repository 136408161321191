import React from "react";
import profileImage from "./img/jenny.jpg";

function Home() {
  return (
    <div className="max-w-4xl flex h-full lg:h-4/6 flex-wrap mx-auto my-0 lg-my-32 lg:my-0 items-center pt-10">
      <div className="w-full lg:w-3/5 rounded-lg lg:rounded-l-lg lg:rounded-r-none shadow-2xl bg-white bg-opacity-75 mx-6 lg:mx-0">
        <div className="p-4 md:p-12 text-center lg:text-left">
          <div className="block lg:hidden rounded-full shadow-xl mx-auto -mt-16 h-48 w-48 bg-[length:160px] bg-[center_top_-0.8rem] profile-image-bg" />

          <h1 className="text-3xl font-bold pt-8 lg:pt-0 tracking-wide">
            Jennifer Bonial
          </h1>
          <div className="mx-auto lg:mx-0 w-4/5 pt-3 border-b-2 border-cyan-500 opacity-25"></div>
          <p className="pt-8 text-sm tracking-wide leading-relaxed font-extrabold">
            Providing comprehensive special education advocacy services to
            empower families and protect student rights.
          </p>

          <div className="pt-12 pb-8">
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdMFmEQXUasYl21hTvrAYOMAX_dpNpxEgqX7pybXgzWOxoB6A/viewform"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-cyan-700 hover:bg-cyan-900 text-white font-bold py-2 px-4 rounded-full"
            >
              Contact
            </a>
          </div>
        </div>
      </div>

      <div className="w-full lg:w-2/5 py-0 md:py-6">
        <img
          src={profileImage}
          height={200}
          className="rounded-none lg:rounded-lg shadow-2xl hidden lg:block h-96"
          alt="Advocate holding books"
        />
      </div>
    </div>
  );
}

export default Home;
