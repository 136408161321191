import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import About from "./About";
import ContactForm from "./ContactForm";
import Home from "./Home";
import Resources from "./Resources";
import Services from "./Services";

function App() {
  return (
    <div className="h-full antialiased text-slate-700 bg-gradient-to-r from-cyan-800 to-emerald-700">
      <div className=" bg-white opacity-75">
        <div className="container sm:mx-5 lg:mx-auto pt-4 lg:pt-10  flex">
          <nav className="grow flex justify-end">
            <ul className="flex flex-row">
              <li className="px-4 text-xl font-bold tracking-wide"><Link to="/services">Services</Link></li>
              <li className="px-4 text-xl font-bold tracking-wide"><Link to="/about">About</Link></li>
              <li className="px-4 text-xl font-bold tracking-wide"><Link to="/resources">Resources</Link></li>
            </ul>
          </nav>
        </div>
        <div className="text-4xl lg:text-6xl xl:text-7xl text-center font-black logo px-1 py-4 pb-10 sm:pb-10 mb-10 lg:pb-16 tracking-wide">
          <Link to="/">
          Boulder Education Advocacy
          </Link>
        </div>
      </div>
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="services" element={<Services />} />
          <Route path="about" element={<About />} />
          <Route path="resources" element={<Resources />} />
          <Route path="contact" element={<ContactForm />} />
        </Routes>
      </main>
    </div>
  );
}

export default App;
